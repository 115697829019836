<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="providersStore.provider.d_licenseNumber + ' - ' +
      providersStore.provider.d_nameLicense" />

      <!--Function Bar-->
      <provider-function-bar />

      <!--Task List-->
      <tasks :key="providersStore.provider.id" :taskSection="2" />

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import TitleBar from '@/components/TitleBar';
import ProviderFunctionBar from '@/components/ProviderFunctionBar';
import Tasks from '@/components/Tasks';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'ProviderTasks',
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: (state) => state.providersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
	  ProviderFunctionBar,
	  Tasks,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('providersStore/read', this.$route.params.providerUUID);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
